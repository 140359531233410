.experience {
	.experience-title-and-company {
		color: rgba($textColour, 0.64);
		font-weight: 600;

		.experience-title {
			color: $textColour;
			font-weight: 700;
		}

		&.promoted {
			margin-top: 2px;
		}
	}

	.experience-location-and-dates {
		@include small-screen {
			text-align: center;
			font-size: 0.9em;
		}

		.experience-location {
			display: inline-block;
			color: rgba($textColour, 0.64);
			margin-top: 0px;
			margin-bottom: 0.5em;
	
			&::after {
				content: ' –';
				color: rgba($textColour, 0.54);
			}
		}
	
		.experience-start-end {
			display: inline-block;
			color: rgba($textColour, 0.54);
			margin-top: 0px;
			margin-bottom: 0.5em;
	
			&::before {
				content: '– ';
			}
		}

		.experience-type {
			display: inline-block;
			margin-top: 0px;
			margin-bottom: 0.5em;
			color: rgba($textColour, 0.64);
			font-size: 0.9em;

			&::before {
				margin-left: 0.32em;
				content: ' 	–– ';
			}
		}
	}

	img.experience-logo {
		float: right;
		width: 150px;
		max-width: 25vw;
		margin-right: 1vw;
		margin-left: 1vw;

		@include small-screen {
			display: none;
		}
	}

	.experience-description {
		p, ul {
			margin-block-start: 0.8em;
			margin-block-end: 0.8em;
		}
	}
}

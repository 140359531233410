$mainColour: #57AD68;
$linkColour: $mainColour;
$smallScreenWidth: 720px;
$pageBackgroundColour: white;
$textColour: black;
@media (prefers-color-scheme: dark) {
	$pageBackgroundColour: #161616;
	$textColour: #EAEAEA;
	$linkColour: lighten($mainColour, 10%);
}

@mixin small-screen {
	@media (max-width: $smallScreenWidth) {
		@content;
	}
}

body {
	font-size: 16px;
	background: $pageBackgroundColour;
	color: $textColour;
}

#page {
	display: grid;
	grid-template-columns: #{'min(20vw, 192px)'} 1fr;
	grid-gap: 2vw;
	margin-top: 2vh;
	width: #{'min(94vw, 1080px)'};
	margin-left: auto;
	margin-right: auto;

	@include small-screen {
		display: flex;
		flex-direction: column;
		width: 100%;
	}
}

a {
	color: $linkColour;
	text-decoration: none;
	transition: all 0.2s;
	outline: none;

	&:hover {
		color: darken($linkColour, 10%);
		text-decoration: underline;
	}

	&:active {
		color: darken($linkColour, 20%);
		text-decoration: underline;
	}
}

header {
	grid-column: 1/1;
	grid-row: 1;
	text-align: right;

	@include small-screen {
		text-align: center;
	}

	img.picture {
		border-radius: 100%;
		width: calc(100% - 2*10px);
		max-width: 512px;
		margin: 0px 0px 10px 10px;

		@include small-screen {
			width: auto;
			margin: 0px;
			max-width: #{'min(64vw, 200px)'};
			max-height: 40vh;
		}
	}

	h1 {
		font-size: 1.2em;
		margin-bottom: 0.2em;

		@include small-screen {
			margin-top: 0.2em;
			margin-bottom: 0.1em;
			font-size: 2em;
		}
	}

	.slogan {
		font-size: 0.9em;
		color: rgba($textColour, 0.64);
		font-style: italic;
		margin-top: 0px;
	}

	nav {
		ul {
			list-style-type: none;
			padding: 0px;

			@include small-screen {
				display: flex;
				justify-content: center;
				font-size: 1.2em;
				margin-top: 0.2em;
				margin-bottom: 0.2em;
			}

			li {
				margin: 8px;

				@include small-screen {
					display: inline-block;
				}

				&.current-page {
					a {
						background: darken($mainColour, 5);
						color: white;
						border-radius: 3px;

						@include small-screen {
							background: inherit;
							color: $linkColour;
							text-decoration: underline;
						}
					}
				}

				a {
					margin-right: -4px;
					padding: 2px 4px;

					@include small-screen {
						display: inline-block;
						padding: 2px 8px;
						transition: all 0.2s;
						border-radius: 3px;
						margin-right: 0px;
	
						&:hover, &:active {
							background: darken($mainColour, 10%);
							text-decoration: none;
							color: white;
						}
					}
				}
			}
		}
	}
}

main {
	grid-column: 2/3;
	grid-row: 1;
	overflow-x: auto;

	@include small-screen {
		margin-left: #{'max(4px, 2vw)'};
		margin-right: #{'max(4px, 2vw)'};
	}
}

h2 {
	font-size: 2.6rem;
	margin-bottom: 0.64em;
}

h3 {
	font-size: 1.8rem;
}

h4 {
	font-size: 1.4rem;
	margin-bottom: 0.4rem;
}

h1, h2, h3, h4, h5, h6, h7 {
	@include small-screen {
		text-align: center;
	}
}

.abbrev {
	color: rgba($textColour, 0.54);
	font-size: 0.9em;
}

@import '_Project';
@import '_Technology';
@import '_Experience';
@import '_ViewSource';
@import '_NotFound';
